class ValidateTool {
  static isPhoneNumber(number) {
    const mobileReg = /^1[3456789]\d{9}$/;
    return mobileReg.test(number);
  }

  //字母数字符号
  static isLetterNumberSymbol(str) {
    const reg = /^[_a-zA-Z0-9`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]+$/im
    return reg.test(str)
  }

  static isStrongPassword(str) {
    const reg1 = /(.*[a-z])/ //至少包含一个小写字母
    const reg2 = /(.*[A-Z])/ //至少包含一个大写字母
    const reg3 = /(.*\d)/ //至少包含一个数字
    const reg4 = /(.*[~!@#$%^&*()_+`\-=\[\]{}|:;"\'<>,.\/?])/ //至少包含一个符号
    const condition1 = reg1.test(str) * 1
    const condition2 = reg2.test(str) * 1
    const condition3 = reg3.test(str) * 1
    const condition4 = reg4.test(str) * 1
    const reg5 = /^.{8,}$/
    const condition5 = reg5.test(str) //大于等于8个字符
    return condition1 + condition2 + condition3 + condition4 >= 3 && condition5
  }
}

export { ValidateTool };
