import CryptoJS from "crypto-js";

export function encrypt(str) {
    if (!str) return '';
    const secret = "respirator_10131"; 
    var key = CryptoJS.enc.Utf8.parse(secret);
    var encrypted = CryptoJS.AES.encrypt(str, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    var encryptedStr = encrypted.toString();
    var encryptedBase64 = CryptoJS.enc.Base64.parse(encryptedStr);
    var encryptedHex = encryptedBase64.toString(CryptoJS.enc.Hex);
    return encryptedHex;
  }